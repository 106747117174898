import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';
import theme from './src/styles/theme';
import React from 'react'

const GATSBY_ORG = 'BKP05';

export function wrapRootElement ({ element }) {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

// Function to init Gatsby only when required.
// This block of code was extracted from:
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-fullstory/src/gatsby-ssr.js
const initGatsby = () => {
  if (window.FS) {
    return null;
  }

  /* eslint-disable */
  window._fs_debug = false;
  window._fs_host = 'fullstory.com';
  window._fs_script = 'edge.fullstory.com/s/fs.js';
  window._fs_org = GATSBY_ORG;
  window._fs_namespace = 'FS';

  // prettier-ignore
  function rawInitGatsby (m,n,e,t,l,o,g,y) {
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src=`https://${window._fs_script}`;
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.anonymize=function(){g.identify(!!0)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.log = function(a,b){g("log",[a,b])};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
    g._v="1.2.0";
  }

  return rawInitGatsby(window,document,window._fs_namespace,'script','user');
  // prettier-ignore
  /* eslint-enable */
};

function setUTMs(queryString) {
  if (!queryString) return;

  const cookies = new Cookies();
  const queryParams = new URLSearchParams(queryString);

  const utmParams = ['utm_medium', 'utm_campaign', 'utm_term', 'utm_source'];
  const cookiesOption = {
    domain: 'pagar.me',
    path: '/',
  };

  utmParams.map(
    (param) => queryParams.get(param) !== null && cookies.set(param, queryParams.get(param), cookiesOption),
  );
}

// This timeout is required because react-helmet can still change the page while this
// hook is called. // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/src/gatsby-browser.js
/* eslint-disable import/prefer-default-export */
export function onRouteUpdate() {
  const optimizeActivate = () => window.dataLayer.push({ event: 'optimize.activate' });

  if (window.dataLayer != null) {
    setTimeout(optimizeActivate, 64);
  }

  const sendPardotView = () => window.piTracker(window.location.href);
  if (window.piTracker != null) {
    setTimeout(sendPardotView, 64);
  }

  setUTMs(window.location.search);

  const trackedPages = ['ofertas'];
  const shouldInitGatsby = trackedPages.some((page) => window.location.pathname.includes(page));
  if (shouldInitGatsby) {
    initGatsby();
  }
}
