const amplitude = require('@amplitude/analytics-browser');
const { Experiment } = require('@amplitude/experiment-js-client');

exports.onRouteUpdate = async function ({ location }, { enabled, apiKey, deploymentKey, environment }) {
  if (!enabled || typeof window === 'undefined') return;

  if (!deploymentKey) throw new Error('Amplitude Experiment Deployment Key not found');

  if (!apiKey) throw new Error('Amplitude API KEY not found');

  console.log('Initiating Amplitude Data');

  await amplitude.init(apiKey, {
    defaultTracking: {
      pageViews: true,
    },
  });

  console.log('Initiating Amplitude Experiment');

  const experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
    fetchOnStart: true,
    debug: environment === 'development',
    automaticExposureTracking: true,
  });

  await experiment.start();

  const currentPage = location.pathname;
  const offerPage = ['/ofertas', '/ofertas/']

  if (offerPage.includes(currentPage)) {
    console.log(`Starting experiment in ${currentPage}`);

    const variant = experiment.variant('pagina-de-ofertas-v2');
    let redirectUrl = variant?.payload?.url;

    if (redirectUrl && redirectUrl !== currentPage) {
      const queryParams = location.search;
      if (queryParams) redirectUrl += queryParams;
      
      console.log(`Redirecting to ${redirectUrl}`);

      window.location.replace(redirectUrl);
    }
  }
};
