module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-44419105-1"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pagar.me","short_name":"Pagar.me","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7f301a7b81cf5908755af8a1bf182bc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../plugins/@pagarme/amplitude/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"environment":"production","apiKey":"6b7a94ea0ee04587c94bef246ea605d3","deploymentKey":"client-2gINmsRa1TgK4LVai8S0XFtEd7ZhKUO9"},
    },{
      plugin: require('../plugins/@pagarme/datadog/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"environment":"production","projectName":"pagarme/website","applicationId":"0c582bfd-cefb-45a0-91db-424d3f537786","clientToken":"puba2106d976b630287c37513edbc37b47a","costCenter":"880370020","owner":"stone-payments/payments-experience-acquisition","sessionSampleRate":10,"sessionReplaySampleRate":10},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
