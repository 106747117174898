const { datadogRum } = require('@datadog/browser-rum')

exports.onClientEntry = function (_, { 
  enabled,
  environment, 
  projectName,
  applicationId,
  clientToken,
  costCenter,
  owner,
  sessionSampleRate = 0,
  sessionReplaySampleRate = 0
  }) {
  if (!enabled || typeof window === 'undefined') return;

  if (!projectName) throw new Error('Project name not found');

  if (!applicationId) throw new Error('Application ID not found');

  if (!clientToken) throw new Error('Client Token not found');

  if (!costCenter) throw new Error('Cost Center not found');

  console.log('Initiating Datadog');

  datadogRum.init({
    applicationId,
    clientToken,
    costCenter,
    project: projectName,
    site: "datadoghq.com",
    service: projectName,
    env: environment,
    sessionSampleRate,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    version: "1.0.0",
    owner,
    support: owner,
  });
};